<template lang="pug">
q-responsive(:ratio="16/12")
  swiper(
    class="swiper-container"
    :slides-per-view="1"
    :autoplay="{ disableOnInteraction: false }"
    :space-between="20"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  )
    swiper-slide(v-for="(image, i) in parentMsg.imgUrls")
      q-responsive(:ratio="16/12")
        img(:src="image" alt="image")
  //- q-carousel(v-model="projectSlide" swipeable animated infinite :autoplay="autoplay" transition-prev="slide-right" transition-next="slide-left")
  //-   q-carousel-slide(:name="i" class="column no-wrap flex-center" v-for="(image, i) in parentMsg.imgUrls" :key="i")
  //-     img(:src="image")
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay
} from 'swiper'
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay
])
export default {
  name: 'Carousel',
  components: {
    Swiper,
    SwiperSlide
  },
  props: [
    'parentMsg'
  ],
  data () {
    return {
      autoplay: true,
      slide: 0,
      projectSlide: 0
    }
  }
}
</script>
<style lang="scss" scoped>
.q-responsive{
  width: 100%;
  background-color: #000;
  .swiper-container{
    img{
      border: solid 7px #000;
      box-sizing: border-box;
      object-fit: cover;
    }
  }
}
// .q-carousel{
//   height: 100%;
//   // height: 65vw;
//   .q-carousel__slide{
//     background-color: #000;
//     padding: 20px;
//   }
//   img{
//     height: 100%;
//     pointer-events: none;
//   }
// }
</style>
